import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./app/store";
import Icon from "./components/common/Icon";
import AskForEmailPermission from "./components/settings/AskForEmailPermission";
import SideBar from "./components/SideBar/SideBar";
import SideBarMobile from "./components/SideBar/SideBarMobile";
const Layout = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const title = location.pathname.split("/")[1];
    const postitle = location.pathname.split("/")[2];

    if (title) {
      document.title = `${title[0].toUpperCase() + title.slice(1)}`;
      if (postitle && postitle.length <= 17 && postitle.length > 3) {
        document.title = `${postitle[0]?.toUpperCase() + postitle?.slice(1)}`;
      }
    } else {
      document.title = `Prima CRM`;
    }
  }, [location]);
  useEffect(() => {
    return () => {
      document.title = "Prima CRM";
    };
  }, []);
  const token = useSelector((state: RootState) => state.login.token);
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="w-full min-h-screen flex flex-col relative bg-prima-bg">
      <AskForEmailPermission />
      <div className="flex flex-1 max-lg:flex-col">
        <div className="w-full lg:hidden">
          <SideBarMobile />
        </div>
        <div className="relative max-lg:hidden">
          <button
            className={`bg-white border shadow-lg rounded-full w-8 aspect-square top-28 fixed z-50 transition-all ${
              !isExpanded ? "left-16" : "left-56"
            }`}
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <Icon
              height={18}
              width={18}
              iconUrl="icons/SideLink2/Back.svg"
              customeClass={`transition-all ml-1.5 ${
                isExpanded ? "" : "rotate-180"
              }`}
            />
          </button>
          <div
            className={`transition-all ${!isExpanded ? "w-20" : "w-60"}`}
          ></div>
          <SideBar isExpanded={isExpanded} />
        </div>
        <div className="px-1 lg:px-7 flex-1 flex-grow w-1 max-lg:w-full">
          <div className="flex-1">
            <Suspense fallback={<div></div>}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
