import axios from "axios";

export async function createEmailSettings(is_email: boolean = false) {
  return await axios.post(
    `setting`,
    {
      notifications: [{ is_email, entity: "property" }],
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export async function getEmailSettings() {
  return await axios.get(`setting`);
}

export async function updateEmailSettings(id: string, updates: any) {
  return axios.patch(`setting/${id}`, updates, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
