import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NotificationSetting {
  is_email: boolean;
  entity: string;
}

interface EmailSettingsState {
  id: string;
  notifications: NotificationSetting[];
}

const initialState: EmailSettingsState = {
  id: "",
  notifications: [],
};

const emailSettingsSlice = createSlice({
  name: "emailSettings",
  initialState,
  reducers: {
    setEmailSettings(
      state,
      action: PayloadAction<{
        id: string;
        notifications: NotificationSetting[];
      }>
    ) {
      state.id = action.payload.id;
      state.notifications = action.payload.notifications;
    },
    addEmailSetting(state, action: PayloadAction<NotificationSetting>) {
      state.notifications.push(action.payload);
    },
    updateEmailSetting(
      state,
      action: PayloadAction<{ entity: string; is_email: boolean }>
    ) {
      const { entity, is_email } = action.payload;
      const notification = state.notifications.find(
        (notification) => notification.entity === entity
      );
      if (notification) {
        notification.is_email = is_email;
      }
    },
    clearEmailSetting() {
      return initialState;
    },
  },
});

export const {
  setEmailSettings,
  addEmailSetting,
  updateEmailSetting,
  clearEmailSetting,
} = emailSettingsSlice.actions;

export default emailSettingsSlice.reducer;
