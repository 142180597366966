import Icon from "../common/Icon";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SubMenu from "./SubMenu";
import RemoveIcon from "../common/RemoveIcon";
import AddIcon from "../common/AddIcon";
export default function SideLink({
  isExpanded,
  onClick,
  text,
  icon,
  href,
  isActive,
  toggleIcon,
  isNew,
  mobile,
}: {
  isExpanded?: boolean;
  onClick?: () => void;
  text: string;
  icon: string;
  href: string;
  isActive: boolean;
  toggleIcon?: string;
  subMenu?: string;
  isNew: boolean;
  mobile?: boolean;
}) {
  const location = useLocation();
  const [toggleSubmenu, setToggleSubmenu] = useState(false);
  function handleToggleSubmenu() {
    setToggleSubmenu(!toggleSubmenu);
  }
  return (
    <NavLink to={href}>
      <li
        onClick={onClick}
        className={` ${
          !isExpanded ? "w-14" : "w-[216px]"
        } h-14 flex flex-wrap justify-start items-center group transition-all rounded-lg ${
          mobile && "rounded-lg"
        } ${isActive ? "bg-white shadow-lg" : ""}`}
      >
        <div
          className={`w-52 flex items-center transition-all text-base font-medium ${
            isExpanded ? "gap-2 pl-5" : "gap-12 pl-3.5"
          } ${!isActive ? "text-white" : "text-dark-blue"}`}
        >
          <Icon
            iconUrl={`/icons/SideLink/${!isActive ? icon : icon + "_bold"}.svg`}
            width={27}
            height={27}
            customeClass="mr-3"
          />
          {text}
          {isNew && (
            <span className="rounded-full text-prima-red px-2 font-bold bg-white text-[10px] leading-4 ml-2 mt-0.5">
              New
            </span>
          )}
          <span className="flex lg:hidden">
            {toggleIcon && (
              <>
                <span
                  className="flex rounded  hover:bg-[#00000084] scale-50 lg:scale-75 "
                  onClick={handleToggleSubmenu}
                >
                  {toggleSubmenu ? (
                    <RemoveIcon size={25} white={!isActive} />
                  ) : (
                    <AddIcon size={25} white={!isActive} />
                  )}
                </span>
              </>
            )}
          </span>
          <span className="hidden lg:flex">
            {toggleIcon && (
              <>
                <span
                  className="flex rounded  hover:bg-[#00000084] scale-50 lg:scale-75"
                  onClick={handleToggleSubmenu}
                >
                  {toggleSubmenu ? (
                    <RemoveIcon size={25} white={!isActive} />
                  ) : (
                    <AddIcon size={25} white={!isActive} />
                  )}
                </span>
              </>
            )}
          </span>
        </div>
      </li>
      {toggleSubmenu && isExpanded && text === "Properties" && (
        <>
          <SubMenu
            isClicked={location.pathname === "/properties/"}
            href="/properties/"
            subMenu="All"
          />
          <SubMenu
            isClicked={location.pathname === "/properties/my_properties"}
            href="/properties/my_properties"
            subMenu="My Properties"
          />
          <SubMenu
            isClicked={location.pathname.startsWith("/properties/archived")}
            href="/properties/archived"
            subMenu="Archived"
          />
        </>
      )}
      {toggleSubmenu && isExpanded && text === "Enquiries" && (
        <>
          <SubMenu
            isClicked={location.pathname.startsWith("/enquiries/valid")}
            href="/enquiries/valid"
            subMenu="Valid"
          />
          <SubMenu
            isClicked={location.pathname.startsWith("/enquiries/invalid")}
            href="/enquiries/invalid"
            subMenu="Invalid"
          />
          <SubMenu
            isClicked={location.pathname.startsWith("/enquiries/nonpersonal")}
            href="/enquiries/nonpersonal"
            subMenu="Non Personal"
          />
        </>
      )}
      {toggleSubmenu && isExpanded && text === "Customers" && (
        <>
          <SubMenu
            isClicked={location.pathname === "/customers/individual"}
            href="/customers/individual"
            subMenu="Individuals"
          />
          <SubMenu
            isClicked={location.pathname === "/companies/"}
            href="/companies/"
            subMenu="Companies"
          />
        </>
      )}
      {toggleSubmenu && isExpanded && text === "Deals" && (
        <>
          <SubMenu
            isClicked={location.pathname.startsWith("/deals/my_deals")}
            href="/deals/my_deals"
            subMenu="My Deals"
          />
          <SubMenu
            isClicked={location.pathname.startsWith("/deals/shared_with_me")}
            href="/deals/shared_with_me"
            subMenu="Shared with me"
          />
        </>
      )}
      {toggleSubmenu && isExpanded && text === "Reports" && (
        <>
          <SubMenu
            isClicked={location.pathname.startsWith("/reports/useractivity")}
            href="/reports/useractivity"
            subMenu="User Activity"
          />
        </>
      )}
    </NavLink>
  );
}
