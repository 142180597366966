import { useEffect, useState } from "react";
import {
  createEmailSettings,
  getEmailSettings,
  updateEmailSettings,
} from "../../api/emailSetting";
import { setEmailSettings, updateEmailSetting } from "../../store/emailSlice";
import { useDispatch, useSelector } from "react-redux";
import { EmailSchema } from "../../validation/emailType";
import { showErrorMessage, showSuccessMessage } from "../../helpers/alert";
import { useQueryClient } from "@tanstack/react-query";
import { RootState } from "../../app/store";
import Icon from "../common/Icon";

export default function AskForEmailPermission() {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id, notifications } = useSelector(
    (state: RootState) => state.emailsetting
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    const loadEmailSettings = async () => {
      try {
        const { data } = await getEmailSettings();

        if (data) {
          dispatch(
            setEmailSettings({
              id: data.id,
              notifications: data.notifications,
            })
          );
        } else {
          const { data: newData } = await createEmailSettings();
          dispatch(
            setEmailSettings({
              id: newData.id,
              notifications: newData.notifications,
            })
          );
          setShowPopup(true);
        }
      } catch (error) {
        console.error("Failed to load or create email settings", error);
      }
    };

    loadEmailSettings();
  }, []);

  const handleEmailNotificationChange = (
    entity: string,
    currentValue: boolean
  ) => {
    const newValue = !currentValue;
    dispatch(updateEmailSetting({ entity, is_email: newValue }));
  };

  const handleSave = async () => {
    setLoading(true);

    const parsed = EmailSchema.safeParse({ id, notifications });
    if (!parsed.success) {
      const { message } = parsed.error.issues[0];
      showErrorMessage(message);
      console.error("Validation error:", message);
      setLoading(false);
      return;
    }

    try {
      if (id) {
        await updateEmailSettings(id, parsed.data);
        showSuccessMessage(
          "The email settings have been updated successfully."
        );
        queryClient.invalidateQueries({ queryKey: ["emailSettings", id] });
      }
    } catch (error) {
      console.error("Error saving email settings:", error);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };
  return (
    showPopup && (
      <div className="absolute top-0 left-0 bg-black/50 h-screen w-screen z-[200]">
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-[220] max-w-[500px] min-w-[300px] bg-white p-5 rounded-xl">
          <Icon
            height={25}
            width={25}
            iconUrl="/icons/CloseSquare_blue.svg"
            onClick={() => setShowPopup(false)}
            customeClass="ml-auto cursor-pointer"
          />
          <div className="mt-3 mb-5 flex flex-col gap-2">
            <img
              src="/setting-guide.png"
              alt="setting guide"
              className="w-52 self-center rounded-lg shadow-lg"
            />
            <div className="font-bold mt-8">Stay Updated on New Listings!</div>
            <div>
              Would you like to receive email notifications for new property
              listings? You can update your email settings anytime, as shown in
              the image.
            </div>
          </div>
          <div className="gap-4 p-4 border border-dark-blue/20 rounded-md">
            {notifications.length > 0 ? (
              notifications.map(
                (notif: { is_email: boolean; entity: string }) => (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={notif.is_email}
                      onChange={() =>
                        handleEmailNotificationChange(
                          notif.entity,
                          notif.is_email
                        )
                      }
                      id={notif.entity}
                    />
                    <label htmlFor={notif.entity} className="ml-2 capitalize">
                      Receive Notifications for Property Activity
                    </label>
                  </div>
                )
              )
            ) : (
              <div className="col-span-2">No email setting found</div>
            )}
          </div>
          <button
            className="w-full text-center py-3 mt-2 bg-dark-blue text-white rounded-lg"
            onClick={handleSave}
            disabled={loading}
          >
            Save
          </button>
        </div>
      </div>
    )
  );
}
