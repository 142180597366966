import { createSlice } from "@reduxjs/toolkit";
import { ListingTypeWizardType } from "../../validation/wizard/listing-type";
import { FileObject } from "../../types/fileType";
const initialListingTypeWizard = {
  data: {},
  is_for_sale: true,
  is_for_rent: false,
  is_prima_management: false,
  photo_ids: [],
  is_exclusive_property: false,
  is_morgaged: false,
  is_free_hold: false,
};
export interface ListingTypeWizardState {
  validationErrors: Partial<ListingTypeWizardType>;
  listingtypewizard: Partial<ListingTypeWizardType> & {
    title_deed_file?: FileObject;
  };
}

const initialState: ListingTypeWizardState = {
  validationErrors: {},
  listingtypewizard: initialListingTypeWizard,
};
export const listingtypewizardSlice = createSlice({
  name: "listingtypewizard",
  initialState,
  reducers: {
    setListingTypeWizard: (state, action) => {
      const newState = { ...state.listingtypewizard, ...action.payload };
      state.listingtypewizard = newState;
    },
    clearListingTypeWizard: (state) => {
      state.listingtypewizard = initialListingTypeWizard;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setListingTypeWizard,
  setValidationErrors,
  clearListingTypeWizard,
  resetValidationErrors,
} = listingtypewizardSlice.actions;
export default listingtypewizardSlice.reducer;
