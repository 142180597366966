import { useEffect, useState } from "react";
import Icon from "./Icon";
import EmailSetting from "./EmailSetting";

interface SettingsProps {
  onCancel: () => void;
}

export default function Settings({ onCancel }: SettingsProps) {
  const [settingState, setSettingState] = useState<"home" | "email">("home");
  const [title, setTitle] = useState("Settings");

  useEffect(() => {
    setTitle(settingState === "home" ? "Settings" : "Email Notification");
  }, [settingState]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1200] text-dark-blue">
      <div className="bg-white border border-gray-300 shadow-md rounded-lg p-4 w-[350px]">
        <div className="flex justify-between items-center border-b pb-3">
          <div className="flex items-center gap-2">
            {settingState !== "home" && (
              <button onClick={() => setSettingState("home")}>
                <Icon
                  height={20}
                  width={20}
                  iconUrl="/icons/Arrow_blue.svg"
                  customeClass="rotate-90"
                />
              </button>
            )}
            <span className="text-lg font-bold text-dark-blue">{title}</span>
          </div>
          <button onClick={onCancel} className="cursor-pointer">
            <Icon
              width={25}
              height={25}
              iconUrl="/icons/DealCard/CloseSquare_blue.svg"
            />
          </button>
        </div>

        {settingState === "home" && (
          <button
            className="transition-all mt-5 w-full bg-dark-blue/10 hover:bg-dark-blue/30 text-dark-blue rounded-md px-3 py-3 flex items-center gap-4"
            onClick={() => setSettingState("email")}
          >
            <Icon height={20} width={20} iconUrl="/icons/Notification.svg" />
            Email Notification
          </button>
        )}

        {settingState === "email" && <EmailSetting />}
      </div>
    </div>
  );
}
