import { createSlice } from "@reduxjs/toolkit";
import { EnquiryType } from "../validation/EnquiryType";

const initialEnquiry = {
  currentPage: 1,
  customer_name: "",
  phone: "",
  phone_dial_id: 784,
  request_for: "to_rent" as "to_rent" | "to_buy",
  assignee_id: "",
  property_id: "",
  search: "",
  customer_message: "",
  isManagerOfPrima: false,
  isEnquiryAssigneeOfPrima: false,
  viewType: "Excel",
  manager_name: "",
  assignee_name: "",
  enquiry_source_title: "",
  phone_dial: "",
  property_type_title: "",
  furnished: false,
  enquiry_id: "",
  email: "",
  customer_type: "buyer",
  enquiry_type: "valid",
  property_title: "",
};
export interface EnquiryState {
  currentPage: number;
  filter: {
    search: string;
    start_date: null;
    end_date: null;
    enquirySource: { value: string; title: string };
    requestFor: { value: string; title: string };
  };
  validationErrors: Partial<EnquiryType>;
  enquiry: Partial<EnquiryType> & {
    enquiry_id?: string;
    search?: string;
    manager_name?: string;
    assignee_name?: string;
    enquiry_source_title?: string;
    phone_dial?: string;
    property_type_title?: string;
    furnished?: boolean;
    property_title: string;
  };
}
const initialFilter = {
  search: "",
  start_date: null,
  end_date: null,
  requestFor: { value: "", title: "" },
  enquirySource: { value: "", title: "" },
};
const initialState: EnquiryState = {
  currentPage: 1,
  filter: initialFilter,
  validationErrors: {},
  enquiry: initialEnquiry,
};
export const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.filter.search = action.payload;
      state.currentPage = 1;
    },
    setStartDate: (state, action) => {
      state.filter.start_date = action.payload;
    },
    setEndDate: (state, action) => {
      state.filter.end_date = action.payload;
    },
    setRequestFor: (state, action) => {
      state.filter.requestFor = action.payload;
      state.currentPage = 1;
    },
    setSource: (state, action) => {
      state.filter.enquirySource = action.payload;
      state.currentPage = 1;
    },
    setEnquiry: (state, action) => {
      state.enquiry = { ...state.enquiry, ...action.payload };
    },
    clearEnquiry: (state) => {
      state.enquiry = initialEnquiry;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    // setIsReady(state, action) {
    //   state.enquiry.isReady = action.payload;
    // },
  },
});
export const {
  setEnquiry,
  clearEnquiry,
  setValidationErrors,
  resetValidationErrors,
  // setSort,
  setSearch,
  setEndDate,
  setStartDate,
  setRequestFor,
  setSource,
  resetFilter,
  setCurrentPage,
  // setIsReady,
} = enquirySlice.actions;
export default enquirySlice.reducer;
