import { createSlice } from "@reduxjs/toolkit";
import { DealType } from "../validation/deal-type";
import { FileObject, FileType } from "../types/fileType";
import { PropertyType } from "../validation/PropertyTypes";

const initialDeal = {
  search: "",
  is_external_property: false,
  assignee_id: "",
  request_for: null,
  prima_broker_commission: 0.5,
  external_agent_id: "",
  external_property_id: "",
  site_visit_id: "",
  property_comment: "",
  is_company_owner: true,
  owner_id: "",
  owner_company_id: "",
  is_company_buyer: true,
  buyer_company_id: "",
  buyer_id: "",
  is_company_registered_dld: true,
  certificate_of_incorporation_id: "",
  passport_copies_id: "",
  visa_page_of_shareholder_ids: [],
  memorandum_of_association_id: "",
  contract_a_id: "",
  contract_b_id: "",
  contract_f_id: "",
  contract_a_expiry_date: "",
  contract_f_expiry_date: "",
  contract_password: "",
  is_kyc_done: false,
  contract_commission_amount: "",
  is_amount_cash: true,
  is_owner_represent_tranfser_sale: false,
  poa_address: "",
  poa_email: "",
  poa_emirates_id: "",
  poa_password_copy_id: "",
  poa_phone_dial_id: 784,
  is_ready_with_phpp: true,
  is_mortgaged: true,
  deal_attachment_ids: [],
  rent_payments: [],
  search_owner: "",
  search_buyer: "",
  search_company_owner: "",
  search_company_buyer: "",
  property_id: "",
  share_with: [],
  is_broker_single: true,
  community: "",
};
const initialFilter = {
  search: "",
  request_for: { value: "", title: "" },
  owner: "",
  buyer: "",
  external_property: false,
  property_title: "",
};
export interface DealState {
  // validationErrors: object;
  currentPage: number;
  validationErrors: Partial<DealType>;
  deal: Partial<DealType> & {
    search: string;
    is_external_property: boolean;
    assignee_id: string;
    request_for: "to_rent" | "to_buy";
    prima_broker_commission: number;
    deal_id?: string;
    deal_attachments?: FileType[];
    contract_a?: FileObject;
    contract_b?: FileObject;
    contract_f?: FileObject;
    mou?: FileObject;
    certificate_of_incorporation?: FileObject;
    passport_copies?: FileObject;
    visa_page_of_shareholders?: FileType[];
    memorandum_of_association?: FileObject;
    noc?: FileObject;
    new_title_dead?: FileObject;
    poa_password_copy?: FileObject;
    poa_emirates_proof?: FileObject;
    poa_document?: FileObject;
    kyc_proof?: FileObject;
    remaining_cheques?: FileObject;
    tenancy_contract?: FileObject;
    addmen?: FileObject;
    search_owner?: string;
    search_buyer?: string;
    search_company_owner?: string;
    search_company_buyer?: string;
    property?: Partial<PropertyType> & { id?: string };
    property_id?: string;
  };
  filter: {
    search: string;
    request_for: { value: string; title: string };
    owner: string;
    buyer: string;
    external_property: boolean;
    property_title: string;
  };
}
const initialState: DealState = {
  currentPage: 1,
  validationErrors: {},
  deal: {
    ...initialDeal,
    request_for: "to_rent",
    property_readiness_status: "ready_no_PHPP",
  },
  filter: initialFilter,
};
export const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    // setBroker: (state, action) => {
    //   state.deal.prima_broker_commission = action.payload;
    //   state.deal.prima_broker = String(
    //     Number(state.deal.prima_company) * state.deal.prima_broker_commission
    //   );
    // },
    setDealType: (state, action) => {
      state.deal.lead_request_for = action.payload;
    },
    setDeal: (state, action) => {
      // if (action.payload.prima_company) {
      //   state.deal.prima_broker = String(
      //     0.5 * Number(action.payload.prima_company)
      //   );
      // }

      if (action.payload.prima_company) {
        state.deal.vat_of_prima_commission = String(
          (0.05 * Number(action.payload.prima_company)).toFixed(2)
        );
      }
      if (action.payload.final_transaction_amount) {
        state.deal.dld_fee = String(
          (0.04 * Number(action.payload.final_transaction_amount)).toFixed(2)
        );
      }
      if (action.payload.deal_attachments) {
        state.deal.deal_attachment_ids = action.payload.deal_attachments.map(
          (item: { file_id: string; id: string }) => item.file_id || item.id
        );
      }
      if (action.payload.visa_page_of_shareholders) {
        state.deal.visa_page_of_shareholder_ids =
          action.payload.visa_page_of_shareholders.map(
            (item: { file_id: string; id: string }) => item.file_id || item.id
          );
      }
      state.deal = { ...state.deal, ...action.payload };
    },
    clearDeal: (state) => {
      state.deal = { ...initialDeal, request_for: "to_rent" };
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    removeFile: (state, action) => {
      state.deal.deal_attachment_ids = state.deal?.deal_attachment_ids?.filter(
        (id) => id !== action.payload
      );
      state.deal.deal_attachments = state.deal.deal_attachments?.filter(
        (photo: FileType) => photo.id !== action.payload
      );
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
    setSearch: (state, action) => {
      state.filter.search = action.payload;
      state.currentPage = 1;
    },
    setOwner: (state, action) => {
      state.filter.owner = action.payload;
      state.currentPage = 1;
    },
    setBuyer: (state, action) => {
      state.filter.buyer = action.payload;
      state.currentPage = 1;
    },
    setPropertyTitle: (state, action) => {
      state.filter.property_title = action.payload;
      state.currentPage = 1;
    },
    setRequestFor: (state, action) => {
      state.filter.request_for = action.payload;
      state.currentPage = 1;
    },
    setExternalProperty: (state, action) => {
      state.filter.external_property = action.payload;
      state.currentPage = 1;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
});
export const {
  // setBroker,
  setDeal,
  setDealType,
  clearDeal,
  setValidationErrors,
  resetValidationErrors,
  removeFile,
  resetFilter,
  setSearch,
  setOwner,
  setBuyer,
  setPropertyTitle,
  setRequestFor,
  setExternalProperty,
  setCurrentPage,
} = dealSlice.actions;
export default dealSlice.reducer;
