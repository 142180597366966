import { hasPermission } from "../../utils/hasPermission";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";
import Profile from "./Profile";
import SideLink from "./SideLink";
import ListCounterUnRead from "../inbox/ListCountUnread";

export default function SideBar({ isExpanded }: { isExpanded: boolean }) {
  const permissions = useSelector(
    (state: RootState) => state.login.permissions
  );
  const transition = " transition-all duration-300";
  return (
    <div
      className={
        `fixed flex flex-col h-full border-r border-dark-blue/20 overflow-x-hidden hide-scrollbar bg-dark-blue z-40 ${
          isExpanded ? "w-60" : "w-20"
        }` + transition
      }
    >
      <div className="absolute h-full bg-black/20 w-2 top-0 right-0 blur-md"></div>
      <div className="flex flex-col items-start absolute top-5 left-3 w-60">
        <div className="absolute">
          <Profile isExpanded={isExpanded} />
        </div>
        <div className="mt-40 flex flex-col relative">
          <div className="absolute top-0.5 right-0.5">
            <ListCounterUnRead />
          </div>
          <SideLink
            isExpanded={isExpanded}
            href="/inbox"
            text="Inbox"
            icon="Inbox"
            isActive={location.pathname.startsWith("/inbox")}
            isNew={false}
          />
          {hasPermission(permissions, "Read", "reports") && (
            <SideLink
              isExpanded={isExpanded}
              href="/dashboard"
              text="Dashboard"
              icon="Diagram"
              isActive={location.pathname.startsWith("/dashboard")}
              isNew={false}
            />
          )}
          {hasPermission(permissions, "Read", "property") && (
            <SideLink
              isExpanded={isExpanded}
              href="/properties"
              text="Properties"
              icon="Building"
              toggleIcon="add"
              isActive={location.pathname.startsWith("/properties")}
              isNew={false}
            />
          )}
          {hasPermission(permissions, "Read", "enquiry") && (
            <SideLink
              isExpanded={isExpanded}
              href="/enquiries"
              text="Enquiries"
              icon="Enquiries"
              toggleIcon="add"
              isActive={location.pathname.startsWith("/enquiries")}
              isNew={false}
            />
          )}
          {hasPermission(permissions, "Read", "customer") && (
            <SideLink
              isExpanded={isExpanded}
              href="/customers"
              text="Customers"
              icon="Customers"
              toggleIcon="add"
              isActive={location.pathname.startsWith("/customers")}
              isNew={false}
            />
          )}
          {hasPermission(permissions, "Read", "lead") && (
            <SideLink
              isExpanded={isExpanded}
              href="/leads"
              text="Leads"
              icon="Leads"
              isActive={location.pathname.startsWith("/leads")}
              isNew={false}
            />
          )}
          {hasPermission(permissions, "Read", "deal") && (
            <SideLink
              isExpanded={isExpanded}
              href="/deals"
              text="Deals"
              icon="Deals"
              toggleIcon="add"
              isActive={location.pathname.startsWith("/deals")}
              isNew={false}
            />
          )}
          {hasPermission(permissions, "Read", "user activity") && (
            <SideLink
              isExpanded={isExpanded}
              href="/reports"
              text="Reports"
              icon="Reports"
              toggleIcon="add"
              isActive={location.pathname.startsWith("/reports")}
              isNew={false}
            />
          )}
          {(hasPermission(permissions, "Add", "user") ||
            hasPermission(permissions, "Read", "role") ||
            hasPermission(
              permissions,
              "All Access",
              "Default (all access: add, edit, delete)"
            )) && (
            <SideLink
              isExpanded={isExpanded}
              href={
                hasPermission(permissions, "Add", "user")
                  ? "/settings/users"
                  : hasPermission(permissions, "Read", "role")
                  ? "/settings/roles"
                  : "/settings/defaults"
              }
              text="Settings"
              icon="Settings"
              isActive={location.pathname.startsWith("/settings/users")}
              isNew={false}
            />
          )}
        </div>
        <div className={`w-48 overflow-hidden transition-all ${isExpanded ? "ml-6" : "ml-20"} mt-10`}>
          <div className="w-40">
            <img src="./primaLogo.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
