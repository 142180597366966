import { useDispatch, useSelector } from "react-redux";
import { getUrl } from "../../utils/fileUrl";
import { RootState } from "../../app/store";
import { useQueryClient } from "@tanstack/react-query";
import { clearLogin, setToken } from "../../store/loginSlice";
import { clearDeal } from "../../store/deal-slice";
import { clearSiteVisit } from "../../store/site-visit-slice";
import { useState } from "react";
import { clearEmailSetting } from "../../store/emailSlice";
import Settings from "../common/Setting";
import Icon from "../common/Icon";

const Profile = ({ isExpanded }: { isExpanded: boolean }) => {
  const login = useSelector((state: RootState) => state.login);
  const profile = login.profile;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [showSettings, setShowSettings] = useState(false);

  return (
    <>
      {showSettings && (
        <Settings
          onCancel={() => {
            setShowSettings(false);
          }}
        />
      )}
      <div className="relative flex flex-col gap-2 justify-between items-center cursor-pointer group hover:h-40">
        <div
          className={`${
            isExpanded ? "w-[216px]" : "w-14"
          } h-14 overflow-hidden transition-all group-hover:h-[156px] bg-white rounded-lg p-1.5 shadow-lg flex flex-col gap-3`}
        >
          <div
            className={`flex items-center transition-all ${
              isExpanded ? "gap-3" : "gap-10"
            }`}
          >
            <div className="min-w-11 w-12 aspect-square rounded-[5px] overflow-hidden shadow-md">
              {profile?.profile_picture != null ? (
                <img
                  className="w-full h-full rounded-md object-cover"
                  src={getUrl(profile?.profile_picture?.path)}
                  alt=""
                />
              ) : (
                <span className="flex justify-center items-center font-bold w-full h-full capitalize text-white bg-dark-blue">
                  {profile?.name.substring(0, 1)}{" "}
                  {profile?.surname.substring(0, 1)}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col font-semibold capitalize text-[16px] leading-5">
              <div>{profile?.name}</div>
              <div>{profile?.surname}</div>
            </div>
          </div>
          <div className="flex flex-col items-center transition-all ">
            <button
              onClick={() => {
                setShowSettings(true);
              }}
              className={`${isExpanded ? "w-[204px]" : "w-11"}
               rounded-md hover:bg-dark-blue/10 `}
            >
              <div
                className={`w-52 flex items-center ${
                  isExpanded ? "gap-3" : "gap-10"
                }`}
              >
                <Icon
                  height={44}
                  width={44}
                  iconUrl="/icons/SideLink2/Setting.svg"
                  customeClass="p-2 rounded-md"
                />
                <div className="w-10 font-medium">Setting</div>
              </div>
            </button>
            <button
              onClick={async () => {
                localStorage.removeItem("token");
                await queryClient.removeQueries();
                dispatch(setToken(null));
                dispatch(clearDeal());
                dispatch(clearSiteVisit());
                dispatch(clearLogin());
                dispatch(clearEmailSetting());
              }}
              className={`${isExpanded ? "w-[204px]" : "w-11"}
               rounded-md hover:bg-dark-blue/10 `}
            >
              <div
                className={`w-52 flex items-center ${
                  isExpanded ? "gap-3" : "gap-10"
                }`}
              >
                <Icon
                  height={44}
                  width={44}
                  iconUrl="/icons/SideLink2/Logout.svg"
                  customeClass="p-2 rounded-md"
                />
                <div className="w-10 font-medium">Logout</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
