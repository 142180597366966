import { useState } from "react";
import Icon from "../common/Icon";
import ProfileMobile from "./ProfileMobile";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { hasPermission } from "../../utils/hasPermission";
import SideLink from "./SideLink";
import ListCounterUnRead from "../inbox/ListCountUnread";

export default function SideBarMobile() {
  const [open, setOpen] = useState(false);
  const permissions = useSelector(
    (state: RootState) => state.login.permissions
  );
  return (
    <div className="w-full h-16 bg-dark-blue flex justify-between items-center px-3 relative">
      <div
        onClick={() => setOpen((prev) => !prev)}
        className="w-10 aspect-square flex items-center justify-center"
      >
        <Icon height={30} width={30} iconUrl="/icons/SideLink/Menu.svg" />
      </div>
      <div>
        <ProfileMobile />
      </div>
      <div
        className={`fixed h-screen bg-dark-blue top-16 left-0 z-[1000] transition-all overflow-hidden ${
          open ? "w-0" : "w-52 shadow-2xl"
        }`}
      >
        <div className="w-52 absolute">
          <div className="mt-5 flex flex-col relative">
            <div className="absolute top-0.5 right-0.5">
              <ListCounterUnRead />
            </div>
            <SideLink
              isExpanded={true}
              href="/inbox"
              text="Inbox"
              icon="Inbox"
              isActive={location.pathname.startsWith("/inbox")}
              isNew={false}
            />
            {hasPermission(permissions, "Read", "reports") && (
              <SideLink
                isExpanded={true}
                href="/dashboard"
                text="Dashboard"
                icon="Diagram"
                isActive={location.pathname.startsWith("/dashboard")}
                isNew={false}
              />
            )}
            {hasPermission(permissions, "Read", "property") && (
              <SideLink
                isExpanded={true}
                href="/properties"
                text="Properties"
                icon="Building"
                toggleIcon="add"
                isActive={location.pathname.startsWith("/properties")}
                isNew={false}
              />
            )}
            {hasPermission(permissions, "Read", "enquiry") && (
              <SideLink
                isExpanded={true}
                href="/enquiries"
                text="Enquiries"
                icon="Enquiries"
                toggleIcon="add"
                isActive={location.pathname.startsWith("/enquiries")}
                isNew={false}
              />
            )}
            {hasPermission(permissions, "Read", "customer") && (
              <SideLink
                isExpanded={true}
                href="/customers"
                text="Customers"
                icon="Customers"
                toggleIcon="add"
                isActive={location.pathname.startsWith("/customers")}
                isNew={false}
              />
            )}
            {hasPermission(permissions, "Read", "lead") && (
              <SideLink
                isExpanded={true}
                href="/leads"
                text="Leads"
                icon="Leads"
                isActive={location.pathname.startsWith("/leads")}
                isNew={false}
              />
            )}
            {hasPermission(permissions, "Read", "deal") && (
              <SideLink
                isExpanded={true}
                href="/deals"
                text="Deals"
                icon="Deals"
                toggleIcon="add"
                isActive={location.pathname.startsWith("/deals")}
                isNew={false}
              />
            )}
            {hasPermission(permissions, "Read", "user activity") && (
              <SideLink
                isExpanded={true}
                href="/reports"
                text="Reports"
                icon="Reports"
                toggleIcon="add"
                isActive={location.pathname.startsWith("/reports")}
                isNew={false}
              />
            )}
            {(hasPermission(permissions, "Add", "user") ||
              hasPermission(permissions, "Read", "role") ||
              hasPermission(
                permissions,
                "All Access",
                "Default (all access: add, edit, delete)"
              )) && (
              <SideLink
                isExpanded={true}
                href={
                  hasPermission(permissions, "Add", "user")
                    ? "/settings/users"
                    : hasPermission(permissions, "Read", "role")
                    ? "/settings/roles"
                    : "/settings/defaults"
                }
                text="Settings"
                icon="Settings"
                isActive={location.pathname.startsWith("/settings/users")}
                isNew={false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
