import { createSlice } from "@reduxjs/toolkit";
import { viewType } from "../validation/viewType";

const initialView = {
  enqViewType: "Excel",
  customerViewType: "Excel",
  companyViewType: "Excel",
  leadViewType: "Excel",
  dealViewType: "Excel",
  userViewType: "Excel",
  propertyViewType: "Excel",
  recordNumber: 10,
  isEditing: false,
};
export interface ViewState {
  view: viewType;
}
const initialState: ViewState = {
  view: initialView,
};
export const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    setView: (state, action) => {
      state.view = { ...state.view, ...action.payload };
    },
    resetView: (state) => {
      state.view = {
        ...state.view,
        selectedId: "",
        selectedName: "",
        selectedProps: {},
      };
    },
  },
});

export const { setView, resetView } = viewSlice.actions;
export default viewSlice.reducer;
