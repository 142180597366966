import { z } from "zod";

const NotificationSchema = z.object({
  is_email: z.boolean(),
  entity: z.string(),
});

export const EmailSchema = z.object({
  id: z.string(),
  notifications: z.array(NotificationSchema),
});
export type EmailType = z.infer<typeof EmailSchema>;
