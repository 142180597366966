import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setEmailSettings, updateEmailSetting } from "../../store/emailSlice";
import {
  createEmailSettings,
  getEmailSettings,
  updateEmailSettings,
} from "../../api/emailSetting";
import { EmailSchema } from "../../validation/emailType";
import { showErrorMessage, showSuccessMessage } from "../../helpers/alert";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export default function EmailSetting() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id, notifications } = useSelector(
    (state: RootState) => state.emailsetting
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    const loadEmailSettings = async () => {
      try {
        const { data } = await getEmailSettings();

        if (data) {
          dispatch(
            setEmailSettings({
              id: data.id,
              notifications: data.notifications,
            })
          );
        } else {
          const { data: newData } = await createEmailSettings();
          dispatch(
            setEmailSettings({
              id: newData.id,
              notifications: newData.notifications,
            })
          );
        }
      } catch (error) {
        console.error("Failed to load or create email settings", error);
      }
    };

    loadEmailSettings();
  }, []);

  const handleEmailNotificationChange = (
    entity: string,
    currentValue: boolean
  ) => {
    const newValue = !currentValue;
    dispatch(updateEmailSetting({ entity, is_email: newValue }));
  };

  const handleSave = async () => {
    setLoading(true);

    const parsed = EmailSchema.safeParse({ id, notifications });
    if (!parsed.success) {
      const { message } = parsed.error.issues[0];
      showErrorMessage(message);
      console.error("Validation error:", message);
      setLoading(false);
      return;
    }

    try {
      if (id) {
        await updateEmailSettings(id, parsed.data);
        showSuccessMessage(
          "The email settings have been updated successfully."
        );
        queryClient.invalidateQueries({ queryKey: ["emailSettings", id] });
      }
    } catch (error) {
      console.error("Error saving email settings:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="mt-3 mb-5">
        Select what emails you would like to receive.
      </div>
      <div className="gap-4 p-4 border border-dark-blue/20 rounded-md">
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={notifications[0].is_email}
            onChange={() =>
              handleEmailNotificationChange(
                notifications[0].entity,
                notifications[0].is_email
              )
            }
            id={notifications[0].entity}
          />
          <label htmlFor={notifications[0].entity} className="ml-2 capitalize">
            Notifications for Property Activity
          </label>
        </div>
      </div>
      <button
        className="w-full text-center py-3 mt-2 bg-dark-blue text-white rounded-lg"
        onClick={handleSave}
        disabled={loading}
      >
        Save
      </button>
    </>
  );
}
