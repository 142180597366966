import { NavLink } from "react-router-dom";

type SubMenuProps = {
  subMenu: string;
  href: string;
  isClicked: boolean;
  className?: string;
};
const SubMenu = ({ subMenu, href, isClicked, className }: SubMenuProps) => {
  return (
    <NavLink to={href}>
      <ul className="ml-10 flex flex-col items-end">
        <li
          className={`w-[80%] flex flex-wrap justify-start items-center py-2 mt-1 group transition-all rounded-lg ${
            isClicked
              ? "bg-white ml-2 shadow-md text-dark-blue"
              : "hover:bg-[#ffffff44] text-white"
          }`}
        >
          <div className="w-40 flex justify-evenly text-sm lg:text-base ">
            <div className={`w-28 flex items-center ${className}`}>
              {subMenu}
            </div>
          </div>
        </li>
      </ul>
    </NavLink>
  );
};

export default SubMenu;
