import { createSlice } from "@reduxjs/toolkit";

const initialRole = {
  permission_ids: [],
  role_name: "",
  user_ids: [],
};
export interface RoleState {
  sort: {
    field: string;
    order: string;
  };
  filter: {
    search: string;
  };
  validationErrors: object;
  role: { permission_ids: string[]; role_name: string; user_ids: string[] };
}
const initialState: RoleState = {
  sort: {
    field: "",
    order: "",
  },
  filter: {
    search: "",
  },
  validationErrors: {},
  role: initialRole,
};
export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setSort: (state, action) => {
      const { field, order } = action.payload;
      state.sort = { field, order };
    },
    setSearch: (state, action) => {
      state.filter.search = action.payload;
    },
    setRole: (state, action) => {
      state.role = { ...state.role, ...action.payload };
    },
    clearRole: (state) => {
      state.role = initialRole;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setRole,
  clearRole,
  setValidationErrors,
  resetValidationErrors,
  setSort,
  setSearch,
} = roleSlice.actions;
export default roleSlice.reducer;
